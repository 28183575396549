<template>
  <div v-if="builderBlock" class="block-builder-container">
    <div
      class="ant-header"
      style="position: relative; display: flex; align-items: center"
    >
      <v-btn
        color="transparent"
        class="elevation-0"
        small
        @click="navigateToWorkflow"
      >
        <v-icon left> mdi-chevron-left </v-icon>
        workflow
      </v-btn>
      <span style="flex: 1">{{ builderBlock.name }}</span>
      <div class="block-builder-actions">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="saveBlockConfig">
              {{
                isBlockSaved ? 'mdi-content-save' : 'mdi-content-save-outline'
              }}
            </v-icon>
          </template>
          <span>Save block configuration</span>
        </v-tooltip>
      </div>
      <div
        v-if="blockConfigStatus === 'loading'"
        class="d-flex justify-center align-center fill-height"
      >
        <ant-loading />
      </div>
    </div>

    <div class="block-config-container">
      <div class="ant-glass-background block-config-navigation">
        <v-select
          v-model="type"
          filled
          dense
          label="Type"
          :items="['manual', 'automated']"
          @change="setBlockType"
        />

        <transition name="fade">
          <div v-if="blockType === 'manual'" class="block-config-template">
            <div
              class="template-panel"
              :class="{
                active:
                  panelToConfigure.vertical === 'top' &&
                  panelToConfigure.horizontal === 'left',
              }"
              @click="
                panelToConfigure = { vertical: 'top', horizontal: 'left' }
              "
            >
              <v-icon>
                {{ getPanelIcon(blockConfig.panels.left.top.type) }}
              </v-icon>
              <div class="panel-type">
                {{ getPanelType(blockConfig.panels.left.top.type) }}
              </div>
            </div>
            <div
              class="template-panel"
              :class="{
                active:
                  panelToConfigure.vertical === 'top' &&
                  panelToConfigure.horizontal === 'right',
              }"
              @click="
                panelToConfigure = { vertical: 'top', horizontal: 'right' }
              "
            >
              <v-icon>
                {{ getPanelIcon(blockConfig.panels.right.top.type) }}
              </v-icon>
              <div class="panel-type">
                {{ getPanelType(blockConfig.panels.right.top.type) }}
              </div>
            </div>
            <div
              class="template-panel"
              :class="{
                active:
                  panelToConfigure.vertical === 'bottom' &&
                  panelToConfigure.horizontal === 'left',
              }"
              @click="
                panelToConfigure = { vertical: 'bottom', horizontal: 'left' }
              "
            >
              <v-icon>
                {{ getPanelIcon(blockConfig.panels.left.bottom.type) }}
              </v-icon>
              <div class="panel-type">
                {{ getPanelType(blockConfig.panels.left.bottom.type) }}
              </div>
            </div>
            <div
              class="template-panel"
              :class="{
                active:
                  panelToConfigure.vertical === 'bottom' &&
                  panelToConfigure.horizontal === 'right',
              }"
              @click="
                panelToConfigure = { vertical: 'bottom', horizontal: 'right' }
              "
            >
              <v-icon>
                {{ getPanelIcon(blockConfig.panels.right.bottom.type) }}
              </v-icon>
              <div class="panel-type">
                {{ getPanelType(blockConfig.panels.right.bottom.type) }}
              </div>
            </div>
          </div>
        </transition>

        <v-btn
          v-if="blockType === 'manual'"
          style="width: 100%; margin-top: 20px"
          color="primary"
          small
          @click="resetPanel()"
        >
          Clear panel
        </v-btn>
      </div>
      <transition name="fade">
        <div class="ant-glass-background block-config-panel">
          <block-manual-panel-builder
            v-if="
              blockType === 'manual' &&
              panelToConfigure.horizontal &&
              panelToConfigure.vertical
            "
            :selected-panel="panelToConfigure"
          />
          <block-automated-panel-builder v-if="blockType === 'automated'" />
        </div>
      </transition>
    </div>

    <v-dialog key="save-changes" v-model="unsavedDialog" width="500px">
      <v-card>
        <v-card-title>
          <span class="headline"> Do you want to save your changes? </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text @click="navigateWithoutSave">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveBlockConfig"> save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BlockManualPanelBuilder from '@/components/WorkflowManagement/Builder/Panels/BlockManualPanelBuilder';
import BlockAutomatedPanelBuilder from '@/components/WorkflowManagement/Builder/Panels/BlockAutomatedPanelBuilder';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'BlockBuilder',
  components: {
    AntLoading,
    BlockAutomatedPanelBuilder,
    BlockManualPanelBuilder,
  },
  data: () => {
    return {
      type: undefined,
      panelToConfigure: {
        vertical: undefined,
        horizontal: undefined,
      },
      unsavedDialog: false,
      navigateUrl: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'builderBlock',
      'blockConfig',
      'blockType',
      'blockConfigStatus',
      'isBlockSaved',
    ]),
  },
  watch: {
    blockType: {
      immediate: true,
      handler(type) {
        this.type = type;
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchBlockData', {
      workflowId: this.$route.params.workflowId,
      blockId: this.$route.params.blockId,
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isBlockSaved) {
      next();
    } else {
      this.unsavedDialog = true;
      this.navigateUrl = to;
    }
  },
  methods: {
    getPanelType(type) {
      if (type === '{TYPE}') {
        return 'not used';
      } else {
        return type;
      }
    },
    getPanelIcon(type) {
      switch (type) {
        case 'form':
          return 'mdi-form-select';
        case 'media':
          return 'mdi-file-image';
        case 'tables':
          return 'mdi-table';
        case '3d-model':
          return 'mdi-rotate-3d';
        case 'esri':
          return 'mdi-map';
        case 'markdown':
          return 'mdi-language-markdown';
        default:
          return '';
      }
    },
    navigateWithoutSave() {
      if (this.navigateUrl !== undefined) {
        this.$store.commit('block_saved_success');
        this.unsavedDialog = false;
        this.$router.push(this.navigateUrl);
      }
    },
    navigateToWorkflow() {
      this.$router.push({
        name: 'workflow-builder',
        params: { workflowId: this.$route.params.workflowId },
      });
    },
    setBlockType(value) {
      this.$store.commit('set_block_type', value);
    },
    saveBlockConfig() {
      const configFile = new Blob([JSON.stringify(this.blockConfig)], {
        type: 'application/json',
      });
      let reader = new FileReader();
      reader.readAsDataURL(configFile);
      reader.onload = () => {
        this.blockBase64 = reader.result.split(',')[1];
        this.$store
          .dispatch('updateBlock', {
            workflowId: this.$route.params.workflowId,
            blockId: this.$route.params.blockId,
            body: {
              config: {
                data: this.blockBase64,
                name: 'config',
                extension: 'json',
              },
            },
          })
          .then(() => {
            this.$store.commit('showNotification', {
              content: 'Block configuration saved',
              color: 'success',
            });
            if (this.navigateUrl) {
              this.$router.push(this.navigateUrl);
            }
          });
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },
    resetPanel() {
      this.$store.commit('reset_panel', this.panelToConfigure);
      this.panelToConfigure = {
        vertical: undefined,
        horizontal: undefined,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.block-builder-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ant-header {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    .block-builder-actions {
      display: flex;

      .v-icon {
        margin-right: 10px;

        &:hover {
          color: var(--v-primary-base);
        }
      }
    }
  }

  .block-config-container {
    flex: 1;
    display: flex;
    min-height: 0;

    .block-config-navigation {
      width: 300px;
      height: 100%;
      margin-right: 20px;
      padding: 10px 12px;

      .block-config-template {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4px;

        .template-panel {
          height: 100px;
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            border: solid 2px var(--v-primary-base);
          }

          &.active {
            border: solid 2px var(--v-primary-base);
          }
        }
      }
    }

    .block-config-panel {
      flex: 1;
      padding: 10px 12px;
      display: flex;
    }
  }
}
</style>
