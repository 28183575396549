<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          Add workflow to collection
        </span>
      </v-card-title>
      <v-form
        ref="form"
        @submit.prevent
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-select
                  v-model="selectedCollection"
                  label="Collection"
                  :items="collections"
                  filled
                  item-value="id"
                  item-text="name"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="addWorkflowToCollection"
        >
          Publish
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {addWorkflowToCollection, getCollections} from "@/services/api/collection";

export default {
  name: "PublishWorkflowDialog",
  props: {
    displayDialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      collections: [],
      selectedCollection: undefined,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    ...mapGetters(['builderWorkflow'])
  },
  watch: {
    displayDialog(bool) {
      if(bool) {
        getCollections().then(collections => {
          this.collections = collections
        })
      }
    }
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset()
      this.$emit('closeDialog')
    },
    addWorkflowToCollection() {
      addWorkflowToCollection(this.selectedCollection, this.builderWorkflow.id).then(() => {
        this.$store.commit('showNotification', {content: 'Workflow added to collection', color: 'success'})
        this.closeDialog()
      })
    }
  }
}
</script>

<style scoped>

</style>
