<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500px"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ section.id === undefined ? 'Create' : 'Update' }} Section
        </span>
      </v-card-title>
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  v-model="section.name"
                  label="Name"
                  filled
                  :rules="[rules.required]"
                />
                <v-select
                  v-model="section.type"
                  :items="['single-input', 'multi-input']"
                  label="Type"
                  filled
                  :rules="[rules.required]"
                />
                <v-text-field
                  v-model="section.table"
                  label="Table name"
                  filled
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="closeDialog"> Cancel </v-btn>
        <v-btn color="primary" @click="createUpdateSection">
          {{ section.id === undefined ? 'Create' : 'Update' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createUUID } from '@/services/uuid-helper';

export default {
  name: 'FormSectionDialog',
  props: {
    displayDialog: {
      type: Boolean,
      default: false,
    },
    editedSection: {
      type: Object,
      default: undefined,
    },
  },
  data: () => {
    return {
      section: {
        id: undefined,
        type: undefined,
        name: undefined,
        table: undefined,
        fields: [],
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  watch: {
    editedSection(section) {
      this.section = Object.assign({}, section);
    },
  },
  methods: {
    closeDialog() {
      this.section = {
        id: undefined,
        type: undefined,
        name: undefined,
        table: undefined,
        fields: [],
      };
      this.$refs.form.reset();
      this.$emit('closeDialog');
    },
    createUpdateSection() {
      if (this.section.id === undefined) {
        this.section.id = createUUID();
        // create section
        this.$emit('createSection', this.section);
        this.closeDialog();
      } else {
        // update section
        this.$emit('updateSection', this.section);
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped></style>
