import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=edb52eec&scoped=true"
import script from "./FormBuilder.vue?vue&type=script&lang=js"
export * from "./FormBuilder.vue?vue&type=script&lang=js"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=edb52eec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edb52eec",
  null
  
)

export default component.exports