<template>
  <div id="grid" class="ant-glass-background build-container">
    <v-progress-linear
      v-if="builderStatus === 'loading'"
      indeterminate
      color="primary"
    />
    <div class="grid-container">
      <svg id="grid-svg-container" :width="svgWidth + 'px'" height="100%">
        <defs>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            :fill="$vuetify.theme.themes.light.primary"
            refX="5"
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7" />
          </marker>
        </defs>
      </svg>
      <div
        v-for="(column, index) in uniqueFlowColumns"
        :key="index"
        class="grid-column"
      >
        <grid-card
          v-for="node in nodesWithColumn(column)"
          :id="node.id"
          :key="node.id"
          :ref="node.id"
          class="grid-card"
          :content="node.block ? node.block : node.workflow"
          :type="node.block ? 'block' : 'workflow'"
          :links="nodeLinks(node)"
          :node-id="node.id"
          :mouse-setting="mouseSetting"
          :class="{
            'selected-start': linkedNodes.start === node,
            'selected-end': linkedNodes.end === node,
          }"
          @selectForLink="selectLink(node)"
          @drawLine="drawSvg"
        />
      </div>
      <div class="grid-column">
        <grid-card
          v-for="node in unlinkedNodes"
          :id="node.id"
          :ref="node.id"
          :key="node.id"
          class="grid-card"
          :content="node.block ? node.block : node.workflow"
          :type="node.block ? 'block' : 'workflow'"
          :links="nodeLinks(node)"
          :node-id="node.id"
          :mouse-setting="mouseSetting"
          :class="{
            'selected-start': linkedNodes.start === node,
            'selected-end': linkedNodes.end === node,
          }"
          @selectForLink="selectLink(node)"
          @drawLine="drawSvg"
        />
      </div>
    </div>
    <div v-if="mouseSetting === 'link'" class="non-conditional-message">
      Links are not conditional
    </div>
    <div v-if="showLinkModal" class="link-container elevation-1">
      <div>
        Are you sure you want to link {{ getNodeName(linkedNodes.start) }} to
        {{ getNodeName(linkedNodes.end) }}
      </div>
      <v-btn color="primary" @click="createLink"> Link </v-btn>
    </div>
    <div v-if="showLinkDeleteModal" class="link-container elevation-1">
      <div>
        Are you sure you want remove this link: {{ deleteLinkStartNodeName }} ->
        {{ deleteLinkEndNodeName }}
      </div>
      <v-btn color="primary" @click="deleteLink"> Delete </v-btn>
    </div>
    <div class="build_options elevation-1">
      <v-btn
        tile
        :color="mouseSetting === 'edit' ? 'primary' : 'white'"
        @click="setMouseSetting('edit')"
      >
        <v-icon left> mdi-pencil </v-icon>
        Edit
      </v-btn>
      <v-btn
        tile
        :color="mouseSetting === 'link' ? 'primary' : 'white'"
        @click="setMouseSetting('link')"
      >
        <v-icon left> mdi-link </v-icon>
        Link
      </v-btn>
      <v-btn
        tile
        :color="mouseSetting === 'delete' ? 'primary' : 'white'"
        @click="setMouseSetting('delete')"
      >
        <v-icon left> mdi-delete </v-icon>
        Delete
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GridCard from '@/components/WorkflowManagement/Builder/GridCard';

export default {
  name: 'BuildGrid',
  components: { GridCard },
  data: () => {
    return {
      mouseSetting: 'edit',
      createBlock: {
        type: 'CREATE',
        name: 'Test',
      },
      linkedNodes: {
        start: undefined,
        end: undefined,
      },
      showLinkModal: false,
      showLinkDeleteModal: false,
      linkIdToDelete: undefined,
      flow: [],
      svgWidth: 0,
    };
  },
  computed: {
    ...mapGetters([
      'builderWorkflow',
      'builderBlocks',
      'builderLinks',
      'builderNodes',
      'builderFlow',
      'builderStatus',
    ]),
    uniqueFlowColumns() {
      return [...new Set(this.builderFlow.map((o) => o.column))].sort(function (
        a,
        b
      ) {
        return a - b;
      });
    },
    unlinkedNodes() {
      let unlinked = [];
      this.builderNodes.forEach((node) => {
        let flowNode = this.builderFlow.find((item) => item.id === node.id);
        if (flowNode === undefined) {
          unlinked.push(node);
        }
      });
      return unlinked;
    },
    deleteLinkStartNodeName() {
      let link = this.builderLinks.find(
        (link) => link.id === this.linkIdToDelete
      );
      if (link !== undefined) {
        let startNode = this.builderNodes.find(
          (node) => node.id === link.previous_node
        );
        if (startNode.block !== null) {
          return startNode.block.name;
        } else {
          return startNode.workflow.name;
        }
      } else {
        return '';
      }
    },
    deleteLinkEndNodeName() {
      let link = this.builderLinks.find(
        (link) => link.id === this.linkIdToDelete
      );
      if (link !== undefined) {
        let endNode = this.builderNodes.find((node) => node.id === link.node);
        if (endNode.block !== null) {
          return endNode.block.name;
        } else {
          return endNode.workflow.name;
        }
      } else {
        return '';
      }
    },
  },
  watch: {
    linkedNodes: {
      deep: true,
      handler(value) {
        this.showLinkModal =
          value.start !== undefined && value.end !== undefined;
      },
    },
  },
  methods: {
    drawSvg(config) {
      let svgContainer = document.getElementById('grid-svg-container');
      this.svgWidth = document.getElementById('grid').scrollWidth;

      // remove old links links if exists
      if (document.getElementById(config.id) !== null) {
        document.getElementById(config.id).remove();
      }

      if (config.y1 === config.y2) {
        let line = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line'
        );
        line.id = config.id;
        line.classList.add('build-links');
        line.setAttribute('x1', config.x1);
        line.setAttribute('y1', config.y1);
        line.setAttribute('x2', config.x2);
        line.setAttribute('y2', config.y2);
        line.setAttribute(
          'stroke',
          this.$vuetify.theme.themes.light.primary.toString()
        );
        line.setAttribute(
          'fill',
          this.$vuetify.theme.themes.light.primary.toString()
        );
        line.setAttribute('stroke-width', 2);
        line.setAttribute('marker-end', 'url(#arrowhead)');
        line.addEventListener('click', this.removeLinkSetup);
        svgContainer.appendChild(line);
      } else {
        let path = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        path.id = config.id;
        path.classList.add('build-links');
        path.setAttribute(
          'd',
          `M ${config.x1} ${config.y1} H ${config.x2 - 25} V ${config.y2} H ${
            config.x2
          }`
        );
        path.setAttribute(
          'stroke',
          this.$vuetify.theme.themes.light.primary.toString()
        );
        path.setAttribute('fill', 'transparent');
        path.setAttribute('stroke-width', 2);
        path.setAttribute('marker-end', 'url(#arrowhead)');
        path.addEventListener('click', this.removeLinkSetup);
        svgContainer.appendChild(path);
      }
    },
    nodeLinks(node) {
      return this.builderLinks.filter((link) => {
        return link.previous_node === node.id;
      });
    },
    nodesWithColumn(column) {
      return this.builderFlow
        .filter((node) => node.column === column)
        .sort((item1, item2) => (item1.row > item2.row ? 1 : -1));
    },
    getNodeName(node) {
      if (node.block !== null) {
        return node.block.name;
      } else {
        return node.workflow.name;
      }
    },
    selectLink(node) {
      if (this.linkedNodes.start === undefined) {
        this.linkedNodes.start = node;
      } else {
        if (this.linkedNodes.start === node) {
          this.linkedNodes.start = undefined;
          this.linkedNodes.end = undefined;
        } else {
          this.linkedNodes.end = node;
        }
      }
    },
    setMouseSetting(setting) {
      this.mouseSetting = setting;
      this.linkedNodes.start = undefined;
      this.linkedNodes.end = undefined;
      this.linkIdToDelete = undefined;
      this.showLinkModal = false;
      this.showLinkDeleteModal = false;
    },
    createLink() {
      let body = {
        previous_node: this.linkedNodes.start.id,
        node: this.linkedNodes.end.id,
      };

      this.$store
        .dispatch('createLink', {
          workflowId: this.$route.params.workflowId,
          link: body,
        })
        .then(() => {
          this.linkedNodes.start = undefined;
          this.linkedNodes.end = undefined;
        });
    },
    removeLinkSetup(event) {
      if (this.mouseSetting === 'delete') {
        this.showLinkDeleteModal = true;
        this.linkIdToDelete = event.target.id;
      }
    },
    deleteLink() {
      this.$store
        .dispatch('removeLink', {
          workflowId: this.$route.params.workflowId,
          linkId: this.linkIdToDelete,
        })
        .then(() => {
          this.showLinkDeleteModal = false;
          this.linkIdToDelete = undefined;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.build-container {
  flex: 1;
  background-image: linear-gradient(
      to right,
      rgba(100, 100, 100, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(100, 100, 100, 0.1) 1px, transparent 1px);
  background-repeat: repeat;
  background-size: 1em 1em;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;

  .non-conditional-message {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: rgba(50, 50, 50, 0.6);
    padding: 5px 8px;
    border-radius: 5px;
    color: white;
  }

  .grid-container {
    display: flex;
    column-gap: 60px;
    flex-direction: row;
    z-index: 2;
    height: 100%;
    padding: 20px 25px;
    position: relative;
    overflow-x: scroll;

    .grid-column {
      display: flex;
      flex-direction: column;
      row-gap: 60px;

      .grid-card {
        position: relative;
        z-index: 2;
      }

      .selected-start {
        outline: solid 4px var(--v-primary-base);
      }

      .selected-end {
        outline: solid 3px var(--v-primary-base);
      }
    }
  }

  .build_options {
    background-color: white;
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 10px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;

    .v-btn {
      width: 100px;
    }
  }

  .link-container {
    background-color: white;
    padding: 20px 28px;
    position: absolute;
    z-index: 2;
    bottom: 60px;
    left: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #grid-svg-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
