<template>
  <div
    v-if="panelConfig"
    class="manual-panel-container"
  >
    <div class="panel-header">
      <v-select
        v-model="panelConfig.type"
        filled
        :items="panelOptions"
        label="Type"
        clearable
        hide-details
      />
    </div>
    <div class="panel-content">
      <div
        v-if="panelConfig.type === 'media'"
        style="height: 100%; overflow: hidden"
      >
        <v-file-input
          id="panelImageInput"
          v-model="inputImage"
          accept="image/png, image/jpeg"
          placeholder="Upload an image"
          prepend-icon="mdi-camera"
          label="Image"
          hide-details
          style="display: none"
          @change="previewImage"
        />
        <div
          v-if="imagePreviewUrl"
          class="image-container"
        >
          <img
            alt=""
            height="400px"
            :src="imagePreviewUrl"
            @click="openImageInput"
          >
        </div>
        <div
          v-else
          class="preview-image-container elevation-1"
          @click="openImageInput"
        >
          <v-icon
            x-large
            color="primary"
          >
            mdi-file-image
          </v-icon>
        </div>
      </div>
      <div v-if="panelConfig.type === 'tables'">
        <v-tabs
          v-model="tableTab"
          grow
        >
          <v-tab>Manual input</v-tab>
          <v-tab>Project input</v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tableTab"
          style="padding: 20px"
        >
          <v-tab-item>
            <v-combobox
              v-model="tables"
              label="Tables"
              clearable
              item-value="id"
              item-text="name"
              outlined
              deletable-chips
              multiple
              chips
              dense
            />
          </v-tab-item>
          <v-tab-item>
            <v-combobox
              v-model="previewProject"
              label="Project"
              clearable
              :items="projects"
              item-value="id"
              item-text="name"
              outlined
              dense
              @change="fetchProjectTables"
            />

            <v-combobox
              v-model="selectedProjectTables"
              label="Tables"
              clearable
              :items="projectTables"
              item-value="id"
              item-text="name"
              outlined
              multiple
              dense
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-if="panelConfig.type === 'modules'">
        <v-tabs
          v-model="moduleTab"
          grow
        >
          <v-tab>Manual input</v-tab>
          <v-tab>Project input</v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="moduleTab"
          style="padding: 20px"
        >
          <v-tab-item>
            <v-combobox
              v-model="module"
              label="Module name"
              clearable
              item-value="id"
              item-text="name"
              outlined
              dense
            />
          </v-tab-item>
          <v-tab-item>
            <v-combobox
              v-model="previewProject"
              label="Project"
              clearable
              :items="projects"
              item-value="id"
              item-text="name"
              outlined
              dense
            />

            <v-combobox
              v-model="selectedProjectModule"
              label="Modules"
              clearable
              :items="previewProject.modules"
              item-value="id"
              item-text="name"
              outlined
              dense
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-if="panelConfig.type === 'form'">
        <form-builder
          ref="formBuilder"
          :config-sections="sections"
        />
      </div>
      <div
        v-if="panelConfig.type === 'markdown'"
        class="panel-markdown"
      >
        <v-textarea
          v-model="markdown"
          class="markdown-input pa-4"
        />
        <markdown-renderer
          class="markdown-output pa-4"
          :source="markdown"
        />
      </div>
    </div>
    <v-btn
      style="margin-top: 10px"
      color="primary"
      @click="savePanel"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import FormBuilder from "@/components/WorkflowManagement/Builder/Forms/FormBuilder";
import {mapGetters} from "vuex";
import MarkdownRenderer from '@/components/MarkdownRenderer';

export default {
  name: "BlockManualPanelBuilder",
  components: { FormBuilder, MarkdownRenderer },
  props: {
    selectedPanel: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      panelOptions: ['media', 'tables', 'modules', 'form', 'markdown'],
      panelConfig: {
        active: false,
        type: undefined,
        content: undefined
      },

      inputImage: undefined,
      imagePreviewUrl: undefined,

      tableTab: 0,
      tables: [],
      previewProject: [],
      selectedProjectTables: [],

      moduleTab: 0,
      selectedProjectModule: undefined,
      module: undefined,

      markdown: undefined,

      sections: [],
    }
  },
  computed: {
    ...mapGetters(['project', 'projects', 'projectTables', 'blockConfig'])
  },
  watch: {
    selectedPanel: {
      immediate: true,
      deep: true,
      handler(value) {
        this.resetManualPanel()
        this.panelConfig = this.blockConfig.panels[value.horizontal][value.vertical]
      }
    },

    panelConfig: {
      immediate: true,
      deep: true,
      handler() {
        if (this.panelConfig.content !== undefined) {
          switch (this.panelConfig.type) {
            case'media':
              if (this.panelConfig.content.extension && this.panelConfig.content.data) {
                this.imagePreviewUrl = `data:image/${this.panelConfig.content.extension};base64,${this.panelConfig.content.data}`
              }
              break;
            case'tables':
              this.tables = this.panelConfig.content.tables
              this.$store.dispatch("fetchProjects")
              break;
            case'modules':
              this.module = this.panelConfig.content.module
              this.$store.dispatch("fetchProjects")
              break;
            case 'markdown':
              this.markdown = atob(this.panelConfig.content.data)
              break;
            case'form':
              this.sections = this.panelConfig.content.sections
              break;
          }
        }
      }
    }
  },
  methods: {
    resetManualPanel() {
      this.inputImage = undefined
      this.imagePreviewUrl = undefined
      this.tableTab = 0
      this.tables = []
      this.previewProject = []
      this.selectedProjectTables = []
      this.moduleTab = 0
      this.selectedProjectModule = undefined
      this.module = undefined
      this.markdown = undefined
      this.sections = []
    },
    fetchProjectTables() {
      if (this.previewProject !== undefined && this.previewProject !== null) {
        this.$store.dispatch("fetchProjectTables", this.previewProject.id)
      }
    },
    openImageInput() {
      document.getElementById('panelImageInput').click()
    },
    previewImage(image) {
      this.imagePreviewUrl = URL.createObjectURL(image);
    },
    savePanel() {
      switch (this.panelConfig.type) {
        case 'media':
          this.convertImageToBase64();
          break;
        case 'tables':
          if (this.tableTab === 0) {
            this.panelConfig.content = {
              tables: this.tables
            }
          } else if (this.tableTab === 1) {
            this.panelConfig.content = {
              tables: this.selectedProjectTables.map(x => x.name)
            }
          }
          break;
        case 'modules':
          if (this.moduleTab === 0) {
            this.panelConfig.content = {
              module: this.module
            }
          } else if (this.moduleTab === 1) {
            this.panelConfig.content = {
              module: this.selectedProjectModule.route
            }
          }
          break;
        case 'markdown':
          this.convertMarkdownToBase64()
          break;
        case 'form':
          this.panelConfig.content = {
            sections: this.$refs.formBuilder.sections
          }
          break;
      }

      this.panelConfig.active = true
      this.$store.commit("save_panel_config", {panelPosition: this.selectedPanel, config: this.panelConfig})
    },
    convertMarkdownToBase64() {
      const markdownFile = new Blob([this.markdown], {type: 'text/plain'})
      let reader = new FileReader();
      reader.readAsDataURL(markdownFile)
      reader.onload = () => {
        this.panelConfig.content = {
          data: reader.result.split(',')[1]
        }
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    convertImageToBase64() {
      let reader = new FileReader();
      reader.readAsDataURL(this.inputImage)
      reader.onload = () => {
        this.panelConfig.content = {
          extension: this.inputImage.type.split('image/')[1],
          data: reader.result.split(',')[1]
        }
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.manual-panel-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .panel-header {
    display: flex;
    margin-bottom: 10px;
  }

  .panel-content {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;

    .no-config-container {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

    }

    .panel-markdown {
      display: flex;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;

      .markdown-input {
        flex: 1;
      }

      .markdown-output {
        flex: 1;
      }
    }

    .image-container {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      cursor: pointer;
      margin: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .preview-image-container {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      cursor: pointer;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin: 20px;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }

}

</style>
