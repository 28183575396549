<template>
  <v-dialog
    v-model="displayDialog"
    max-width="900px"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ field.id === undefined ? 'Create' : 'Update' }} Field
        </span>
      </v-card-title>
      <v-form ref="fieldForm" @submit.prevent>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-select
                  v-model="field.type"
                  :items="['text', 'number', 'select', 'document']"
                  label="Type"
                  filled
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="9">
                <v-text-field
                  v-model="field.column"
                  label="Table column"
                  filled
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-switch v-model="field.required" label="Required" filled />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="field.label"
                  label="Label"
                  filled
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="field.suffix" label="Suffix" filled />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="field.placeholder"
                  label="Placeholder"
                  filled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="field.tooltip" label="Tooltip" filled />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="field.type === 'text' || field.type === 'number'"
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="field.default"
                  label="Default value"
                  filled
                />
              </v-col>
              <v-col v-if="field.type === 'number'" cols="12" sm="6" md="4">
                <v-text-field
                  v-model="field.min"
                  label="min value"
                  filled
                  type="number"
                />
              </v-col>
              <v-col v-if="field.type === 'number'" cols="12" sm="6" md="4">
                <v-text-field
                  v-model="field.max"
                  label="max value"
                  filled
                  type="number"
                />
              </v-col>
              <v-combobox
                v-if="field.type === 'select'"
                v-model="field.items"
                label="Selectable Items"
                :rules="[rules.required]"
                clearable
                outlined
                deletable-chips
                multiple
                chips
              />
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="closeDialog"> Cancel </v-btn>
        <v-btn color="primary" @click="createUpdateField">
          {{ field.id === undefined ? 'Create' : 'Update' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createUUID } from '@/services/uuid-helper';

export default {
  name: 'FormFieldDialog',
  props: {
    displayDialog: {
      type: Boolean,
      default: false,
    },
    editedField: {
      type: Object,
      default: undefined,
    },
  },
  data: () => {
    return {
      field: {
        id: undefined,
        required: false,
        column: undefined,
        label: undefined,
        placeholder: undefined,
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  watch: {
    editedField(field) {
      console.log(field);
      this.field = Object.assign({}, field);
    },
  },
  mounted() {
    if (this.editedField !== undefined) {
      this.field = Object.assign({}, this.editedField);
    }
  },
  methods: {
    closeDialog() {
      this.field = {
        id: undefined,
        required: false,
        column: undefined,
        title: undefined,
        placeholder: undefined,
      };
      this.$refs.fieldForm.reset();
      this.$emit('closeDialog');
    },
    createUpdateField() {
      if (this.field.id === undefined) {
        this.field.id = createUUID();
        this.field.required = !!this.field.required;

        // create field
        this.$emit('createField', this.field);
        this.closeDialog();
      } else {
        this.field.required = !!this.field.required;

        // update field
        this.$emit('updateField', this.field);
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
