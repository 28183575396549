<template>
  <div
    class="card-container"
    @click="openDialog()"
  >
    <v-icon class="card-icon">
      {{ type === 'workflow' ? 'mdi-source-branch' : 'mdi-cube' }}
    </v-icon>
    <div class="card-header">
      {{ content.name }}
    </div>
    <div class="card-description">
      {{ content.description }}
    </div>
    <v-btn
      v-if="type === 'block'"
      color="primary"
      x-small
      @click.stop="configureBlock(content.id)"
    >
      Configure
    </v-btn>
    <block-dialog
      v-if="type === 'block'"
      :block="content"
      :display-dialog="editDialog"
      @closeDialog="editDialog = false"
    />
    <delete-dialog
      :title="`Are you sure you want to remove '${content.name}' from the workflow`"
      :dialog="deleteDialog"
      @closeDialog="deleteDialog = false"
      @deleteAction="deleteCard"
    />
  </div>
</template>

<script>
import DeleteDialog from "@/components/DeleteDialog";
import BlockDialog from "@/components/WorkflowManagement/Builder/BlockDialog";
import {mapGetters} from "vuex";

export default {
  name: "GridCard",
  components: {BlockDialog, DeleteDialog},
  props: {
    content: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    nodeId: {
      type: String,
      required: true
    },
    mouseSetting: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      editDialog: false,
      deleteDialog: false,
    }
  },
  computed: {
    ...mapGetters(['builderLinks']),
    links() {
      return this.builderLinks.filter(link => {
        return link.previous_node === this.nodeId
      })
    }
  },
  watch: {
    links(newValue, oldValue) {
      const results = oldValue.filter(({ id: id1 }) => !newValue.some(({ id: id2 }) => id2 === id1));
      results.forEach(link => {
        document.getElementById(link.id).remove()
      })

      this.drawLine()
    },
  },
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine() {
      if (this.links !== null) {
        let element = document.getElementById(this.nodeId)
        this.links.forEach(link => {
          let previousElement = document.getElementById(link.node)

          let lineConfig = {
            x1: element.offsetLeft + (element.offsetWidth / 2) + 115,
            y1: element.offsetTop + (element.offsetHeight / 2),
            x2: previousElement.offsetLeft + (previousElement.offsetWidth / 2) - 115 - 8,
            y2: previousElement.offsetTop + (previousElement.offsetHeight / 2),
            id: link.id
          }

          this.$emit("drawLine", lineConfig)
        })
      }
    },
    openDialog() {
      switch (this.mouseSetting) {
        case "edit":
          this.editDialog = true;
          break;
        case "delete":
          this.deleteDialog = true;
          break;
        case "link":
          this.$emit('selectForLink')
          break;
      }
    },
    configureBlock(blockId) {
      this.$router.push({name: 'block-builder', params: {workflowId: this.$route.params.workflowId, blockId: blockId}})
    },
    deleteCard() {
      if (this.type === 'block') {
        this.$store.dispatch("removeBlock", {workflowId: this.$route.params.workflowId, blockId: this.content.id})
            .then(() => {
              this.deleteDialog = false
            })
      } else if (this.type === 'workflow') {
        this.$store.dispatch("removeWorkflowNode", {workflowId: this.$route.params.workflowId, nodeId: this.nodeId})
            .then(() => {
              this.deleteDialog = false
            })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.card-container {
  width: 230px;
  padding: 10px 12px;
  background-color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  .card-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .card-header {
    color: var(--v-primary-base);
    font-size: 16px;
  }

  .card-description {
    font-size: 14px;
  }
}

</style>
